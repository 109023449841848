import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataTable from "../../../components/AdminComponent/reactDataTable/ReactDataTable";
import { gameApis } from "../../../services/games/gameApi";
import { IGame } from "../../../types/game.types";
import "./Category.scss";

const Category = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [categoryPerPage, setCategoryPerPage] = useState(10);

  useEffect(() => {
    getAllCategory();
  }, [currentPage, categoryPerPage]);

  const getAllCategory = async () => {
    setLoading(true);
    try {
      const response: any = await gameApis.getGameAllTypes();
      if (response.status === 200) {
        setCategoryList(response?.data?.response);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.status === 400) {
        toast.error(error.msg);
      }
    }
  };

  const columns: TableColumn<IGame>[] = [
    {
      name: "Sr",
      maxWidth: "20px",
      //   center: true,
      cell: (_: any, index: number) => <span>{index + 1}</span>,
      sortable: true,
    },
    {
      name: "Category Name",
      // minWidth: "400px",
      //   center: false,
      style: { textAlign: "start" },
      cell: (row: any) => (
        <div>
          <span className="mb-3">{row?.name}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Actions",
      //   center: true,
      sortable: false,
      cell: (row: any) => (
        <div className="video-actions d-flex gap-3">
          <EditIcon
            style={{
              cursor: "pointer",
            }}
            color="primary"
            onClick={() => navigate(`/category/edit/${row?._id}`)}
          />
        </div>
      ),
    },
  ];

  const handleRedirect = () => {
    navigate("/category/create");
  };

  return (
    <div className="category-container">
      <ReactDataTable
        isGameTable={false}
        data={categoryList}
        columns={columns}
        loading={loading}
        headerBtnToRedirect={() => handleRedirect()}
        headerBtnName="Add New Category"
        isHeaderBtn={false}
      />
    </div>
  );
};

export default Category;

import React, { useEffect, useState } from "react";
import "./PopUpAds.scss";
import { gameApis } from "../../../services/games/gameApi";
import { toast } from "react-toastify";

const AdUploadComponent = () => {
  const [photo, setPhoto] = useState<File | null>(null);
  const [description, setDescription] = useState<string>("");
  const [ad, setAd] = useState<any>(null);
  const [isAdVisible, setIsAdVisible] = useState<any>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setPhoto(file);
    }
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    const fetchPopUpData = async () => {
      try {
        const response = await gameApis.getPopUpData();
        const data = response.data.response[0];
        setAd(data);
        setIsAdVisible(data.is_public);
      } catch (error: any) {
        toast.error(error);
      }
    };

    fetchPopUpData();
  }, []);

  const createAd = async () => {
    if (!photo || !description) {
      alert("Please provide both an image and description.");
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("popup_img", photo);
    formData.append("description", description);
    formData.append("is_public", isAdVisible);

    if (isEditing) {
      try {
        const response = await gameApis.updatePopUpData(formData, ad._id);
        toast.success(response.data.msg);
        setAd(response.data.response);
        setIsEditing(false);
      } catch (error) {
        toast.error("Failed to create ad.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      try {
        const response = await gameApis.createPopup(formData);
        toast.success(response.data.msg);
        setAd(response.data.response);
      } catch (error) {
        toast.error("Failed to create ad.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const editAd = () => {
    if (ad) {
      setPhoto(ad.image_url);
      setDescription(ad.description);
    }
    setIsEditing(!isEditing);
  };

  const deleteAd = async () => {
    try {
      await gameApis.deletePopUpData(ad._id);
      setAd(null);
      setIsAdVisible(false);
      toast.success("Ad deleted successfully");
    } catch (error) {
      toast.error("Failed to delete ad.");
    }
  };

  const toggleAdVisibility = async () => {
    const formData: any = new FormData();
    formData.append("is_public", !isAdVisible);

    try {
      await gameApis.updatePopUpData(formData, ad._id);
      setIsAdVisible(false);
      setIsAdVisible(!isAdVisible);
      toast.success(
        !isAdVisible ? "Ad visible successfully" : "Ad hide successfully"
      );
    } catch (error) {
      toast.error("Failed to delete ad.");
    }
  };

  return (
    <div className="ad-upload-container">
      <h2>{ad ? "Edit" : "Upload"} Your Ad</h2>

      {(!ad || isEditing) && (
        <div className="ad-form">
          <div className="photo-upload">
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              disabled={isSubmitting}
            />
          </div>

          <div className="description-input">
            <textarea
              placeholder="Enter your ad description..."
              value={description}
              onChange={handleDescriptionChange}
              disabled={isSubmitting}
            />
          </div>

          <div className="toggle-ad">
            <button onClick={createAd} disabled={isSubmitting}>
              {isSubmitting
                ? "Creating..."
                : !isEditing
                ? "Create Ad"
                : "Update Ad"}
            </button>
          </div>
        </div>
      )}

      {ad && !isSubmitting && !isEditing && (
        <div className="toggle-ad">
          <button onClick={editAd}>Edit Ad</button>
        </div>
      )}

      {ad && (
        <div className="delete-ad">
          <button onClick={deleteAd}>Delete Ad</button>
        </div>
      )}

      {ad && (
        <div className="toggle-visibility">
          <button onClick={toggleAdVisibility}>
            {isAdVisible ? "Hide Ad" : "Show Ad"}
          </button>
        </div>
      )}

      {isAdVisible && ad && (
        <div className="ad-preview">
          <h3>Your Ad Preview</h3>
          <div className="ad-preview-content">
            <img src={ad.image_url} alt="Ad" className="ad-img" />
            <p>{ad.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdUploadComponent;

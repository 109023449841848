import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import ReactDataTable from "../../../components/AdminComponent/reactDataTable/ReactDataTable";
import { gameApis } from "../../../services/games/gameApi";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import "./Category.scss";

const CategoryList = () => {
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryPerPage, setCategoryPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
  const [formData, setFormData] = useState<any>({
    title: "",
    label: "",
    image: null,
  });
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<any | null>(null);

  useEffect(() => {
    getAllCategory();
  }, [currentPage, categoryPerPage]);

  const getAllCategory = async () => {
    setLoading(true);
    try {
      const response: any = await gameApis.getCategoryList();
      if (response.status === 200) {
        setCategoryList(response?.data?.response);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.status === 400) {
        toast.error(error.msg);
      }
    }
  };

  const columns: TableColumn<any>[] = [
    {
      name: "Sr",
      maxWidth: "20px",
      cell: (_: any, index: number) => <span>{index + 1}</span>,
    },
    {
      name: "Category Name",
      style: { textAlign: "start" },
      cell: (row: any) => (
        <div>
          <span>{row?.label}</span>
        </div>
      ),
    },
    {
      name: "Image",
      center: true,
      cell: (row: any) => (
        <div className="game-das-row-img-wrap">
          <img
            src={row?.image_url || "https://picsum.photos/100"}
            alt={row.name}
          />
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row: any) => (
        <div className="video-actions d-flex gap-3">
          <EditIcon
            style={{
              cursor: "pointer",
            }}
            color="primary"
            onClick={() => handleEdit(row)}
          />
          {row?.is_deletable && (
            <DeleteIcon
              style={{
                cursor: "pointer",
              }}
              color="error"
              onClick={() => handleDelete(row)} // Trigger delete confirmation
            />
          )}
        </div>
      ),
    },
  ];

  const handleRedirect = () => {
    setIsCreating(true);
    setFormData({
      title: "",
      label: "",
      image: null,
    });
    setImagePreview(null);
    setOpenModal(true);
  };

  const handleEdit = (category: any) => {
    setIsCreating(false);
    setSelectedCategory(category);
    setFormData({
      title: category.value,
      label: category.label,
      image: category.image_url || null,
    });
    setImagePreview(category.image_url || null);
    setOpenModal(true);
  };

  const handleDelete = (category: any) => {
    setCategoryToDelete(category);
    setOpenDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (categoryToDelete) {
      try {
        const response = await gameApis.deleteCategoryList(
          categoryToDelete._id
        );
        if (response.status === 200) {
          toast.success("Category deleted successfully!");
          setOpenDeleteModal(false);
          getAllCategory();
        } else {
          toast.error("Failed to delete category.");
        }
      } catch (error: any) {
        toast.error(error.msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCategory(null);
    setFormData({ title: "", label: "", image: null });
    setImagePreview(null);
    setTimeout(() => {
      setIsCreating(false);
    }, 200);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData((prev: any) => ({
        ...prev,
        image: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (selectedCategory) {
      const formDataToSend = new FormData();
      formDataToSend.append("label", formData.label);
      formDataToSend.append("_id", selectedCategory._id);

      if (formData.image && formData.image instanceof File) {
        formDataToSend.append("category_img", formData.image);
      }

      try {
        setLoading(true);
        const response = await gameApis.updateCategoryList(formDataToSend);
        if (response.status === 200) {
          toast.success("Category updated successfully!");
          setOpenModal(false);
          getAllCategory();
        } else {
          toast.error("Failed to update category.");
        }
      } catch (error: any) {
        toast.error(error.msg);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCreateCategory = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("label", formData.label);
    formDataToSend.append("value", formData.title);

    if (formData.image && formData.image instanceof File) {
      formDataToSend.append("category_img", formData.image);
    }

    try {
      const response = await gameApis.createCategoryList(formDataToSend);
      if (response.status === 200) {
        toast.success("Category created successfully!");
        setOpenModal(false);
        getAllCategory();
      } else {
        toast.error("Failed to create category.");
      }
    } catch (error: any) {
      toast.error(error.msg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="category-container">
      <ReactDataTable
        isGameTable={false}
        data={categoryList}
        columns={columns}
        loading={loading}
        headerBtnToRedirect={() => handleRedirect()}
        headerBtnName="Add New Category"
        isHeaderBtn={true}
      />

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>
          {isCreating ? "Create New Category" : "Edit Category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Category Title"
            name="title"
            value={formData.title}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
            disabled={!isCreating}
          />
          <TextField
            label="Category Label"
            name="label"
            value={formData.label}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />

          <div className="image-upload-container">
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={handleImageChange}
            />
            <div
              className="image-upload-message"
              style={{ marginTop: "10px", fontSize: "14px", color: "#666" }}
            >
              <p>
                <strong>Image format:</strong> WEBP only
              </p>
              <p>
                <strong>Resolution:</strong> 64x64 px with high resolution
              </p>
            </div>
            {imagePreview && (
              <div
                className="image-preview-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="image-preview"
                  style={{
                    marginTop: "10px",
                    height: "250px",
                  }}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button
            onClick={isCreating ? handleCreateCategory : handleSubmit}
            color="primary"
          >
            {isCreating ? "Create Category" : "Save Changes"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>
            Are you sure you want to delete <b> {categoryToDelete?.label}</b>{" "}
            category?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CategoryList;
